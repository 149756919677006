<template>
  <div class="home" @click.stop="istypeshow = false">
    <swiper></swiper>
    <div class="header_top">
      <handleback></handleback>
      <div class="top_title">{{ $t('h.playWithBlindBoxes') }}</div>
      <div class="speac"></div>
    </div>
    <div class="box_fenlei">
      <div class="box_type_low">
        <!-- <div @click.stop="
          handleChangeType('', '盲盒类别');
        playAudio();
        " class="type_btn center" :class="boxPage.boxTypeId == '' ? 'active' : ''">
          全部
        </div> -->
        <div class="type_btn center" :class="boxPage.boxTypeId == item.id ? 'active' : ''"
          v-for="(item, index) in headernava" :key="index" @click.stop="
            handleChangeType(item.boxTypeId, item.boxTypeName, index);
          playAudio();
          ">
          {{ item.boxTypeName }}
        </div>
      </div>
    </div>
    <div class="box" @scroll="handleScroll">
      <div class="box_type">
        <div class="boxs grade2">
          <div v-for="(v, index) in www" :key="index" class="boxs_list btn_click" @click.stop="
            handleGo(v.boxId);
          playAudio();
          ">
            <div class="hot" v-if="false">
              <div class="hots">{{ $t('h.latest') }}</div>
            </div>
            <div class="box_list_img center">
              <img :src="v.boxImg01" alt width="100%" class="bg1" />
              <img v-if="v.boxImg02" :src="v.boxImg02" alt width="100%" class="bg2" />
            </div>
            <div class="box_list_name">{{ v.boxName }}</div>
            <div class="box_list_const">
              <money class="money"></money>
              {{ v.price }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swiper from "@/components/swiperUser.vue";
import { getArticle, getBoxList, getBoxTypeList } from "@/api/index";

export default {
  data() {
    return {
      istypeshow: false,
      boxTypeList: [],
      typePage: {
        page: 1,
        size: 10,
      },
      boxList: [],
      tableList: [],
      boxPage: {
        boxTypeId: "",
        boxTypeName: this.$i18n.t('h.blindBoxCategory'),
        gameMouldId: 1,
        page: 1,
        size: 10,
        orderByFie: 2,
      },
      getAll: false,
      isOver: this.$i18n.t('h.loading'),
      scroll: "",
      headernava: [],
      www: []
    };
  },
  components: { swiper },
  mounted() {
    //this.getTypeList();
    this.getList();
  },
  activated() {
    let box = document.getElementsByClassName('box')
    console.log(box);
    if (this.scroll > 0) {
      box[0].scrollTo(0, this.scroll);
      this.scroll = 0;
    }
  },
  methods: {
    handleChangeType(res, name, index) {
      console.log(res, name)
      this.www =  this.boxList[index].boxList;

    },
    handleScroll(e) {
      this.event = e;
      this.scroll = e.srcElement.scrollTop;
      if (
        e.srcElement.scrollTop + e.srcElement.clientHeight >=
        e.srcElement.scrollHeight
      ) {
        if (this.isOver == this.$i18n.t('h.loading')) {
          this.boxPage.page++;
          setTimeout(() => {
            this.getList();
          }, 5);
        }
      }
    },
    playAudio() {
      this.$store.commit("playAudio");
    },
    handleilltate() {
      this.$bus.$emit("is_illustrate", true);
      setTimeout(() => {
        getArticle("wzmh").then((res) => {
          let dream = res.data.data.contentList[0].content;
          this.$bus.$emit("nr_illustrate", dream);
        });
      }, 500);
    },
    handleGo(res) {
      this.$router.push({
        path: "/openbox",
        query: {
          id: res,
        },
      });
    },
    getTypeList() {
      if (this.boxTypeList.length == 0) {
        getBoxTypeList(this.typePage).then((res) => {
          if (res.data.code == 200) {

            console.log(res.data, 'woyao de shuju')
            this.boxTypeList.push(...res.data.data.records);
            if (res.data.data.records.length == this.typePage.size) {
              this.typePage.page++;
              this.getTypeList();
            } else {
              this.getAll = true;
            }
          }
          console.log("玩法列表", this.boxTypeList);
        });
      }
    },
    getList() {
      let data={
        boxType:1
      }
      getBoxList(data).then((res) => {

        if (res.data.code == 200) {

          this.headernava = res.data.data
          // this.boxTypelist = res.data.data;
          /*   let arr = res.data.data; */
          /* arr.map((item) => {
            return (item.boxNum = 0);
          }); */
          this.boxList = res.data.data;
          console.log(this.boxList, 'woyaode shuju ');
          this.www = res.data.data[0].boxList;
          if (res.data.data.length < this.boxPage.size) {
            this.isOver = this.$i18n.t('h.noMoreAvailable');
          }
        }
      });
      // getBoxList(this.boxPage).then((res) => {
      //   // console.log("箱子列表", res.data.data);
      //   if (res.data.code == 200) {
      //     let arr = res.data.data;
      //     arr.forEach((item) => {
      //       if (this.boxList.get(item.boxTypeId)) {
      //         this.boxList.get(item.boxTypeId).push(item);
      //       } else {
      //         this.boxList.set(item.boxTypeId, [item]);
      //       }
      //     });
      //     console.log("箱子列表map", this.boxList);
      //     if (res.data.data.length < this.boxPage.size) {
      //       this.isOver = this.$i18n.t('h.noMoreAvailable');
      //     }
      //     this.boxTypeList.map((item) => {
      //       return (item.children = this.boxList.get(item.id));
      //     });
      //     this.tableList = this.boxTypeList.filter((item) => {
      //       return item.children;
      //     });
      //     console.log("处理后的分类", this.tableList);
      //   }
      // });
    },
  },
  watch: {
    getAll(newValue) {
      if (newValue) {
        this.getList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box_fenlei {
  z-index: 99;
  width: 100%;
  height: 42px;
  background: #323436;
  opacity: 1;
  position: absolute;

  @media (max-width: 550px) {
    height: 30px;
    font-size: 11px;
    top: 126px;
  }

  .box_type_low {
    overflow-x: auto;
    width: 100%;
    top: 45px;
    background: #323436;
    opacity: 1;
    display: flex;

    @media (max-width: 550px) {
      top: 35px;
      // height: 30px;
    }

    &::-webkit-scrollbar {
      width: 2px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #25f484;
      border-radius: 5px;
    }

    @media (max-width: 550px) {
      &::-webkit-scrollbar {
        width: 2px;
        height: 6px;
      }
    }

    .active {
      background: linear-gradient(360deg,
          rgba(37, 244, 132, 0.5) 0%,
          rgba(37, 244, 132, 0.25) 100%);
      opacity: 1;
      border: 1px solid #25f484;
    }

    .type_btn {
      height: 42px;
      width: 100px;
      flex-shrink: 0;
      float: left;
      overflow: hidden;
      border-radius: 21px;
      font-size: 12px;
      @media (max-width: 550px) {
        height: 30px;
      }
    }
  }
}

.speac {
  width: 54px;

  @media (max-width: 550px) {
    width: 35px;
  }
}

.box {
  margin: 0 auto;
  width: 98%;
  height: 500px;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #25f484;
    border-radius: 5px;
  }

  @media (max-width: 550px) {
    height: 535px;

    &::-webkit-scrollbar {
      width: 2px;
    }
  }

  overflow-y: auto;

  .box_name {
    background-size: 100% 100%;
    font-size: 25px;

    @media (max-width: 550px) {
      font-size: 18px;
    }
  }
}

.boxs {
  width: 100%;
  // display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 37px;
}

.box_type {
  margin-top: 35px;
}

.boxs_list {
  width: 100%;
  margin-top: 14px;
  position: relative;
  padding-top: 1px;

  @media (max-width: 550px) {
    margin-top: 14px;
    margin-top: 0;
  }

  .hot {
    width: 56px;
    height: 42px;
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;

    .hots {
      position: absolute;
      right: -5px;
      top: 8px;
      width: 69px;
      height: 21px;
      background: linear-gradient(90deg, #3a97e4 0%, #25f484 100%);
      box-shadow: 0px 3px 7px 0px rgba(37, 244, 132, 0.5);
      opacity: 1;
      transform: rotate(-14deg);
      font-size: 16px;
      color: #181a1c;
      line-height: 21px;
    }
  }
}

.boxs {
  .boxs_list:last-child {
    // margin-right: auto;
  }
}

.box_list_img {
  width: 88%;
  // height: 181px;
  margin: 0 auto;
  position: relative;
  top: 0;

  .bg1 {}

  .bg2 {
    width: 151px;
    // height: 151px;
    position: absolute;
    // left: 50%;
    // left: 0;
    // transform: translateX(-50%);
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    .bg2 {
      // animation: boxscola 2s ease infinite;
      transition: all 0.2s ease;
      transform: scale(1.2);
    }
  }
}

.box_list_name {
  margin-top: 22px;
  font-size: 17px;
  color: #fff;

  @media (max-width: 550px) {
    font-size: 12px;
  }
}

.box_list_const {
  margin: 0 auto;
  margin-top: 14px;
  width: 89%;
  height: 42px;
  background: linear-gradient(-270deg,
      rgba(37, 244, 132, 0.5) 0%,
      rgba(37, 244, 132, 0) 100%);
  border: 1px solid #25f484;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  line-height: 1;

  @media (max-width: 550px) {
    height: 30px;
  }
}

.header_top {
  width: 98%;
  height: 56px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div:first-child {}

  div:last-child {
    color: #fff;
    font-size: 12px;
    text-decoration: underline;
    line-height: 1.5;
  }
}

.home {
  width: 95%;
  margin: 0 auto;
}
</style>
